<template>
	<div class="ion-page">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<ion-header>
			<ion-toolbar color="primary-contrast">
				<ion-buttons slot="start">
					<ion-menu-button></ion-menu-button>
				</ion-buttons>
				<div class="ion-text-center">
					<img src="../assets/logo.png" height="100px"/>
				</div>
			</ion-toolbar>
		</ion-header>

		<ion-content>
			<ion-list>
				<router-link class="nav-link" :to="{name:'List'}">
					<ion-item>
						<ion-icon slot="start" color="medium" name="list"></ion-icon>
						<ion-label>Elenco</ion-label>
					</ion-item>
				</router-link>
				<router-link class="nav-link" :to="{name:'GreenPass'}">
					<ion-item>
						<ion-icon slot="start" color="medium" name="qr-scanner"></ion-icon>
						<ion-label>Green Pass</ion-label>
					</ion-item>
				</router-link>
			</ion-list>
		</ion-content>
	</div>
</template>

<script>

// Import stylesheet
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'HomePage',
	props: {
		msg: String
	},
	data: function(){
		return {
			isLoading: false,
			fullPage: true,
		}
	},

	components: {
		Loading
	},

	mounted: function(){
		var token = localStorage.getItem("user_token");

		if(token === null || token == ''){
			location.href = '#/login';
		}

	},
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	
</style>

<template>
	<div class="ion-page">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<ion-header>
			<ion-toolbar color="primary-contrast">
				<ion-buttons slot="start">
					<ion-menu-button></ion-menu-button>
				</ion-buttons>
				<div class="ion-text-center">
					<img src="../assets/logo.png" height="100px"/>
				</div>
			</ion-toolbar>
		</ion-header>

		<ion-content class="ion-padding">
			<div class="ion-text-center">
				<h1>Elenco</h1>
			</div>
			<section>
				<v-select class="search-conference" :options="conferences" v-model="conference" placeholder="Cerca una conferenza"
					@search="searchConferences" :filterable="false" @input="rememberConference">
					<template #no-options="{ search, searching }">
						<template v-if="searching">
							Nessuna conferenza trovata per <em>{{ search }}</em>.
						</template>
						<em style="opacity: 0.5;" v-else>Inizia a scrivere per cercare una conferenza.</em>
					</template>
				</v-select>

				<div v-if="subscribers.length > 0" class="subscribers-list">
					<div v-for="(subscriber, index) in subscribers" v-bind:key="index">
						<ion-card v-if="(typeof results[index] === 'undefined' || results[index] === null) && subscriber !== null" @click="registerSubscriber(subscriber, index)">
							<ion-card-header>
								<ion-card-title>{{subscriber.surname}} {{subscriber.name}}</ion-card-title>
								<div v-if="typeof errors[index] !== 'undefined' && errors[index] !== null" class="error">
									{{ errors[index] }}
								</div>
							</ion-card-header>
						</ion-card>
						<div v-if="typeof results[index] !== 'undefined' && results[index] !== null" class="registration-result">
							<p class="subscriber">
								<img src='../assets/checkmark.svg' alt="Checkmark" width="40px" />
								<b>{{ results[index].subscriber }}</b>
							</p>
							<p v-html="results[index].message" class="message"></p>
						</div>	
					</div>	
				</div>
				<div v-else class="subscribers-list">
					<p class="ion-margin">Non ci sono iscritti<span v-if="direction == 'IN'"> in entrata</span><span v-if="direction == 'OUT'"> in uscita</span> per questa conferenza.</p>
				</div>
				
			</section>
		</ion-content>

		<ion-footer no-border>
			<div class="toggle-direction-container">
				<toggle-switch :options="toggleOptions" v-model="toggleSelected" @change="updateDirection($event.value)" /> 
			</div>
		</ion-footer>
	</div>
</template>

<script>
import axios from 'axios';

// Import component
import Loading from 'vue-loading-overlay';
import vSelect from "vue-select";
import ToggleSwitch from 'vuejs-toggle-switch';

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-select/dist/vue-select.css";

export default {
	name: 'ListPage',
	props: {
		msg: String
	},
	data: function(){
		return {
			isLoading: false,
			fullPage: true,
			errors: [],
			results: [],
			conference: '',
			direction: 'IN',
			toggleSelected: '',
			conferences: [],
			subscribers: [],
			toggleOptions: {
				layout: {
					color: '#007aff',
					backgroundColor: '#ffffff',
					borderColor: '#007aff',
					fontFamily: 'Arial',
					fontWeight: 'normal',
					fontWeightSelected: 'bold',
					squareCorners: false,
					noBorder: false
				},
				size: {
					fontSize: '1.2',
					height: '2.5',
					padding: '0.5',
					width: '15'
				},
				items: {
					delay: .4,
					preSelected: '',
					labels: [
						{name: 'ENTRATA', color: '#ffffff', backgroundColor: '#007aff'}, 
						{name: 'USCITA', color: '#ffffff', backgroundColor: '#007aff'}
					]
				}
			}
		}
	},

	components: {
		Loading,
		'v-select': vSelect,
		'toggle-switch': ToggleSwitch
	},

	mounted: function(){
		var token = localStorage.getItem("user_token");

		if(token === null || token == ''){
			location.href = '#/login';
		}

		this.direction = localStorage.getItem("subscribers_registration_direction");
		
		if (this.direction === 'IN') {
			this.toggleSelected = 'ENTRATA';
		} else if (this.direction === 'OUT') {
			this.toggleSelected = 'USCITA';
		} else {
			this.toggleSelected = 'ENTRATA';
			this.updateDirection('ENTRATA');
		}

		this.initConferences();
	},

	methods: {

		initConferences () {
			this.isLoading = true;

			var token = localStorage.getItem("user_token");

			axios.get(process.env.VUE_APP_BASE_URL + 'webApp/searchConferences?search=', {
				headers: {
					'Authorization': 'Bearer ' + token
				}
			})
			.then(response => {
				if (response.data.body.response == 'OK') {
					this.conferences = response.data.body.data; 

					if (this.conferences.length > 0) {
						var selectedConference = localStorage.getItem("selected_conference");

						if (selectedConference) {
							this.conferences.forEach((conference) => {
								if (conference.id == selectedConference) {
									this.conference = conference;
									this.getConferenceSubscribers();
								}
							});
						}
					}

					if (!this.conference) {
						localStorage.setItem("selected_conference", '');
						alert('Attenzione! Si prega di selezionare una conferenza.');
					}
				} else if (response.data.body.response == 'TOKEN-KO') {
					localStorage.setItem("user_token", "");
					location.href = '#/login';
				}
				
				this.isLoading = false;
			})
			.catch(e => {
				// eslint-disable-next-line
				console.log(e);
				
				alert('Errore nella ricerca delle conferenze.');

				this.isLoading = false;
			});
        },

		searchConferences (search, loading) {
			loading(true);

			var token = localStorage.getItem("user_token");

			axios.get(process.env.VUE_APP_BASE_URL + 'webApp/searchConferences?search='+encodeURI(search), {
				headers: {
					'Authorization': 'Bearer ' + token
				}
			})
			.then(response => {
				if (response.data.body.response == 'OK') {
					this.conferences = response.data.body.data; 
				} else if (response.data.body.response == 'TOKEN-KO') {
					localStorage.setItem("user_token", "");
					location.href = '#/login';
				}
				
				loading(false);
			})
			.catch(e => {
				// eslint-disable-next-line
				console.log(e);
				
				alert('Errore nella ricerca delle conferenze.');

				loading(false);
			});
        },

		getConferenceSubscribers () {
			this.isLoading = true;

			var token = localStorage.getItem("user_token");

			axios.get(process.env.VUE_APP_BASE_URL + 'webApp/getConferenceSubscribers?conference='+encodeURI(this.conference.id)+'&direction='+encodeURI(this.direction), {
				headers: {
					'Authorization': 'Bearer ' + token
				}
			})
			.then(response => {
				if (response.data.body.response == 'OK') {
					this.subscribers = response.data.body.data; 
				} else if (response.data.body.response == 'TOKEN-KO') {
					localStorage.setItem("user_token", "");
					location.href = '#/login';
				}
				
				this.isLoading = false;
			})
			.catch(e => {
				// eslint-disable-next-line
				console.log(e);
				
				alert('Errore nella ricerca degli iscritti alla conferenza.');

				this.isLoading = false;
			});
		},

		registerSubscriber (subscriber, index) { 
			let vm = this;
			this.isLoading = true;

			this.results = [];
			this.errors = [];

			var token = localStorage.getItem("user_token");

			axios.get(process.env.VUE_APP_BASE_URL + 'webApp/getSubscriber/'+subscriber.id, {
				headers: {
					'Authorization': 'Bearer ' + token
				}
			})
			.then(response => {
				if (response.data.body.response == 'OK') {
					subscriber = response.data.body.data;
					if (this.direction) {
						var register = true;
						if (
							!subscriber.last_registration && this.direction == 'OUT'
							|| subscriber.last_registration.direction == this.direction
						) {
							var confirmMsg = "";
							if (!subscriber.last_registration) {
								confirmMsg = "Attenzione! Questa risulta essere la prima timbratura per l'iscritto. Si è sicuri di voler timbrare in USCITA?";
							} else if (subscriber.last_registration.direction == 'IN') {
								confirmMsg = "Attenzione! La precedente timbratura salvata per l'iscritto risulta in ENTRATA. Si è sicuri di voler timbrare nuovamente in ENTRATA?";
							} else if (subscriber.last_registration.direction == 'OUT') {
								confirmMsg = "Attenzione! La precedente timbratura salvata per l'iscritto risulta in USCITA. Si è sicuri di voler timbrare nuovamente in USCITA?";
							}
							
							if (!confirm(confirmMsg)) {
								register = false;
							}
						}

						if (register) {

							let params = new URLSearchParams();
							params.append('subscription_id', subscriber.id);
							params.append('direction', this.direction);

							axios.post(process.env.VUE_APP_BASE_URL + 'webApp/registerSubscriber', params, {
								headers: {
									'Authorization': 'Bearer ' + token
								}
							})
							.then(response2 => {
								if (response2.data.body.response == 'OK') {
									var msg = '';
									if (response2.data.body.data == 'LOW') {
										msg = "Il tempo di presenza dell'iscritto è <b>inferiore</b> al minimo richiesto.";
									} else if (response2.data.body.data == 'OK') {
										msg = "Il tempo di presenza dell'iscritto ha <b>raggiunto</b> il minimo richiesto.";
									}
									var result = {
										subscriber: subscriber.surname.toUpperCase() + ' ' + subscriber.name.toUpperCase(),
										message: msg
									};
									vm.$set(vm.results, index, result);
									vm.$set(vm.subscribers, index, null);
								} else if (response2.data.body.response == 'KO') {
									vm.$set(vm.errors, index, response2.data.body.msg);
								} else if (response2.data.body.response == 'TOKEN-KO') {
									localStorage.setItem("user_token", "");
									location.href = '#/login';
								}
								this.isLoading = false;
							})
							.catch(e => {
								// eslint-disable-next-line
								console.log(e);

								this.isLoading = false;
								vm.$set(vm.errors, index, "Errore nella timbratura dell'iscritto.");
							});
						} else {
							this.isLoading = false;
						}
					} else {
						this.isLoading = false;
						this.errors[index] = "Errore nella timbratura dell'iscritto: dati mancanti.";
					}
				} else if (response.data.body.response == 'KO') {
					alert(response.data.body.msg);
				} else if (response.data.body.response == 'TOKEN-KO') {
					localStorage.setItem("user_token", "");
					location.href = '#/login';
				}
				
				this.isLoading = false;
			})
			.catch(e => {
				// eslint-disable-next-line
				console.log(e);
				
				alert("Errore nella ricerca dell'iscritto.");

				this.isLoading = false;
			});
		},

		reset () {
			this.results = [];
			this.errors = [];
			this.subscribers = [];
		},

		rememberConference () {
			if (this.conference) {
				localStorage.setItem("selected_conference", this.conference.id);
				this.getConferenceSubscribers();
			} else {
				localStorage.setItem("selected_conference", '');
				this.reset();
			}
		},

		updateDirection (value) {
			if (value == 'ENTRATA') {
				this.direction = 'IN';
			} else if (value == 'USCITA') {
				this.direction = 'OUT';
			} else {
				this.direction = '';
			}
			localStorage.setItem("subscribers_registration_direction", this.direction);
			this.reset();
			if (this.conference) {
				this.getConferenceSubscribers();
			}
		},
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	section {
		margin-top: 2em;
		margin-left: 1em;
		margin-right: 1em;
    }
	ion-card-content {
		color: #000000;
	}
	.error {
		margin-top: 10px;
		font-weight: bold;
		color: red;
	}
	ion-footer{
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px;
	}
	.toggle-direction-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 15px;
	}
	.vs__selected ~ .vs__search {
		position: absolute;
		top: 0px;
		width: 100%;
	}
	.vs__selected ~ .vs__search:focus {
		position: relative;
	}
	.subscribers-list {
		margin-top: 30px;
	}
	.registration-result {
		margin: 10px;
	}
	.registration-result .subscriber {
		display: flex;
		align-items: center;
		font-size: 16px;
	}
	.registration-result .subscriber img {
		margin-right: 5px;
	}
	.registration-result .message {
		position: relative;
		top: -10px;
	}
</style>

<template>
    <div>
        <ion-content class="ion-padding">
            <h3 class="title">ERRORE</h3>
            <p class="error" v-html="error"></p>
            <ion-button expand="block" color="primary" class="btn-close" @click="closeModal()">Continua</ion-button>
        </ion-content>
    </div>
</template>

<script>

export default {
    name: 'ModalError',
    props: [
        "error"
    ],
    data() {
        return {
            
        }
    },
    methods: {
        closeModal: function() {
            this.$ionic.modalController.dismiss();
        }
    }
};
</script>

<style scoped>
    .btn-close {
        height: 72px;
    }
    .title {
        color: #ff0000;
    }
    .error {
		font-weight: bold;
		color: red;
	}
</style>
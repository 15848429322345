<template>
    <div class="ion-page">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<ion-header>
			<ion-toolbar color="primary-contrast">
				<div class="ion-text-center">
					<img src="../assets/logo.png" height="100px"/>
				</div>
			</ion-toolbar>
		</ion-header>

		<ion-content>
			<ion-grid>
				<ion-row class="row-login" color="primary" justify-content-center> 
					<ion-col align-self-center size-md="6" size-lg="5" size-xs="12">
						<ion-item>
							<ion-input type="text" :value="username" @ionInput="username = $event.target.value; updateInvalid()" placeholder="Username"></ion-input>
						</ion-item>
						<ion-item>
							<ion-input type="password" :value="password" @ionInput="password = $event.target.value; updateInvalid()" placeholder="Password"></ion-input>
						</ion-item>
						<ion-button size="large" :disabled="invalid" expand="block" class="login-btn" @click="login()">Login</ion-button>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-content>

		<!--
		<ion-footer>
			<img src="../assets/logo_eu.jpeg" />
			<img src="../assets/logo_ministero_lavoro.jpeg" />
			<img src="../assets/logo_ministero_interno.jpeg" />
		</ion-footer>
		-->
	</div>
</template>

<script>

import axios from 'axios';

// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'RefuelsPage',
  data: function(){
        return {
            username: '',
            password: '',
			invalid: true,
            isLoading: false,
            fullPage: true
        }
  },
  components: {
      Loading
  },
  mounted: function() {
		var token = localStorage.getItem("user_token");
		
		if(token !== null && token != ''){
			location.href = '#/list';
		}
  },
  methods: {

	login() { 
		let params = new URLSearchParams();
		params.append('username', this.username);
		params.append('password', this.password);

        axios.post(process.env.VUE_APP_BASE_URL + 'webApp/loginApp', params)
        .then(response => {
            // JSON responses are automatically parsed.
            // eslint-disable-next-line
			//console.log(response);
			
			if(response.data.body.response == 'OK'){
				localStorage.setItem("user_token", response.data.body.data);

				location.reload();
			}else if(response.data.body.response == 'KO'){
				alert(response.data.body.msg);
			}
            
            this.isLoading = false;
        })
        .catch(e => {
            // eslint-disable-next-line
            console.log(e);
            
            alert('Errore nell\'autenticazione.');

            this.isLoading = false;
        });
	},

	updateInvalid(){
		if(this.username == '' || this.password == ''){
			this.invalid = true;
		}else{
			this.invalid = false;
		}
	},
  }
}
</script>

<style>
.row-login{
	margin-top: 20px;
}
.login-btn{
    margin-top: 20px;
}
.logo-card{
	margin: 0px;
}
ion-footer{
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
}
</style>
<template>
    <div>
        <ion-content class="ion-padding">
            <p>Sono stati trovati i seguenti iscritti. Selezionare quello corretto.</p>
            <ion-card v-for="(subscriber, index) in subscribers" v-bind:key="subscriber.id" @click="selectSubscriber(index)">
                <ion-card-content>
                    {{ subscriber.name }} {{ subscriber.surname }}<br>
                    {{ subscriber.birthday }}<br>
                    {{ subscriber.email }}
                </ion-card-content>
            </ion-card>
            <ion-button expand="block" color="primary" @click="closeModal()">Annulla</ion-button>
        </ion-content>
    </div>
</template>

<script>

export default {
    name: 'ModalSubscribersList',
    props: [
        "subscribers"
    ],
    data() {
        return {
            
        }
    },
    methods: {
        selectSubscriber: function(index) {
            this.$ionic.modalController.dismiss(this.subscribers[index]);
        },
        closeModal: function() {
            this.$ionic.modalController.dismiss();
        }
    }
};
</script>
<template>
    <div>
        <ion-content class="ion-padding">
            <h3 class="title">UTENTE NON ISCRITTO</h3>
            <p>Sono stati trovati i seguenti utenti non iscritti alla conferenza.</p>
            <p>L'utente si deve iscrivere e successivamente ripetere il check-in.</p>
            <p>Indicare all'utente la persona che si può occupare dell'iscrizione.</p>
            <ion-card v-for="user in users" v-bind:key="user.id">
                <ion-card-content>
                    {{ user.nome }} {{ user.cognome }}<br>
                    {{ user.data_nascita }}<br>
                    {{ user.email }}
                </ion-card-content>
            </ion-card>
            <ion-button expand="block" color="primary" class="btn-close" @click="closeModal()">Continua</ion-button>
        </ion-content>
    </div>
</template>

<script>

export default {
    name: 'ModalUsersList',
    props: [
        "users"
    ],
    data() {
        return {
            
        }
    },
    methods: {
        closeModal: function() {
            this.$ionic.modalController.dismiss();
        }
    }
};
</script>

<style scoped>
    .btn-close {
        height: 72px;
    }
    .title {
        color: #ff0000;
    }
</style>
<template>
	<div id="app">
		<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
		<ion-app>
			<ion-split-pane>
				<ion-menu contentId="content">
					<ion-header>
						<ion-item color="primary">
							{{ username }}
						</ion-item>
					</ion-header>

					<ion-content>
						<ion-list>
							<ion-menu-toggle auto-hide="false" >
								<router-link class="nav-link" :to="{name:'Home'}">
									<ion-item>
										<ion-icon slot="start" color="medium" name="home"></ion-icon>
										<ion-label>Home</ion-label>
									</ion-item>
								</router-link>
							</ion-menu-toggle>
							<ion-menu-toggle auto-hide="false" >
								<router-link class="nav-link" :to="{name:'List'}">
									<ion-item>
										<ion-icon slot="start" color="medium" name="list"></ion-icon>
										<ion-label>Elenco</ion-label>
									</ion-item>
								</router-link>
							</ion-menu-toggle>
							<ion-menu-toggle auto-hide="false" >
								<router-link class="nav-link" :to="{name:'GreenPass'}">
									<ion-item>
										<ion-icon slot="start" color="medium" name="qr-scanner"></ion-icon>
										<ion-label>Green Pass</ion-label>
									</ion-item>
								</router-link>
							</ion-menu-toggle>
							<ion-menu-toggle auto-hide="false" >
								<ion-item @click="logout()">
									<ion-icon slot="start" color="medium" name="log-out"></ion-icon>
									<ion-label>Logout</ion-label>
								</ion-item>
							</ion-menu-toggle>
						</ion-list>
					</ion-content>
					<ion-footer no-border>
						<ion-item lines="none">
							<em style="color:gray;">v{{ appVersion }}</em>
						</ion-item>
					</ion-footer>
				</ion-menu>

				<ion-router-outlet id="content" main>
					<router-view/>
				</ion-router-outlet>

			</ion-split-pane>
		</ion-app>
	</div>
</template>

<script>

import axios from 'axios';

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'App',
	data: function(){
		return {
			isLoading: false,
			fullPage: true,
			appVersion: this.$appVersion,
			username: ''
		}
	},

	mounted: function(){

		var token = localStorage.getItem("user_token");

		if(token === null || token == ''){
			location.href = '#/login';
		}else{
			this.isTokenValid(token);
		}

	},

	methods: {

		logout() { 
			var token = localStorage.getItem("user_token");
			axios.post(process.env.VUE_APP_BASE_URL + 'webApp/logoutApp', {}, {
				headers: {
					'Authorization': 'Bearer ' + token
				}
			})
			.then(() => {
				localStorage.setItem("user_token", "");
				location.href = '#/login';
			})
			.catch(e => {
				// eslint-disable-next-line
				console.log(e);

				localStorage.setItem("user_token", "");
				location.href = '#/login';
			});
		},
		
		isTokenValid(token) {
			axios.get(process.env.VUE_APP_BASE_URL + 'webApp/isTokenValid', {
				headers: {
					'Authorization': 'Bearer ' + token
				}
			})
			.then(response => {
				if(response.data.body.response == 'TOKEN-KO'){
					localStorage.setItem("user_token", "");
						location.href = '#/login';
				}else{
					this.username = response.data.body.data.username;
				}
			})
			.catch(e => {
				// eslint-disable-next-line
				console.log(e);
				
				alert('Errore nella verifica del token.');

				localStorage.setItem("user_token", "");
				location.href = '#/login';
			});
		}
	}
}
</script>

<style>
.nav-link{
	text-decoration: none;
}
:root { 
	--ion-color-primary: #3C8DBC;
	--ion-color-primary-rgb: 56,128,255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #3171e0;
	--ion-color-primary-tint: #4c8dff;
	--ion-color-secondary: #0cd1e8;
	--ion-color-secondary-rgb: 12,209,232;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #0bb8cc;
	--ion-color-secondary-tint: #24d6ea;
	--ion-color-tertiary: #7044ff;
	--ion-color-tertiary-rgb: 112,68,255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #633ce0;
	--ion-color-tertiary-tint: #7e57ff;
	--ion-color-success: #10dc60;
	--ion-color-success-rgb: 16,220,96;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255,255,255;
	--ion-color-success-shade: #0ec254;
	--ion-color-success-tint: #28e070;
	--ion-color-warning: #ffce00;
	--ion-color-warning-rgb: 255,206,0;
	--ion-color-warning-contrast: #ffffff;
	--ion-color-warning-contrast-rgb: 255,255,255;
	--ion-color-warning-shade: #e0b500;
	--ion-color-warning-tint: #ffd31a;
	--ion-color-danger: #f04141;
	--ion-color-danger-rgb: 245,61,61;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #d33939;
	--ion-color-danger-tint: #f25454;
	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34,34,34;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;
	--ion-color-medium: #989aa2;
	--ion-color-medium-rgb: 152,154,162;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #86888f;
	--ion-color-medium-tint: #a2a4ab;
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244,244,244;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;
}
</style>

<template>
	<div class="ion-page">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<ion-header>
			<ion-toolbar color="primary-contrast">
				<ion-buttons slot="start">
					<ion-menu-button></ion-menu-button>
				</ion-buttons>
				<div class="ion-text-center">
					<img src="../assets/logo.png" height="100px"/>
				</div>
			</ion-toolbar>
		</ion-header>

		<ion-content class="ion-padding">
			<div class="ion-text-center">
				<h1>Green Pass</h1>
			</div>
			<section>
				<v-select class="search-conference" :options="conferences" v-model="conference" placeholder="Cerca una conferenza"
					@search="searchConferences" :filterable="false" @input="rememberConference">
					<template #no-options="{ search, searching }">
						<template v-if="searching">
							Nessuna conferenza trovata per <em>{{ search }}</em>.
						</template>
						<em style="opacity: 0.5;" v-else>Inizia a scrivere per cercare una conferenza.</em>
					</template>
				</v-select>

				<div v-if="conference">

					<ion-button v-if="!showQrCodeReader" expand="block" color="primary" class="btn-start-qrcodereader" @click="startQrCodeReader()">AVVIA</ion-button>

					<div v-if="showQrCodeReader">
						<p class="error" v-html="error"></p>
						<div v-if="result" class="qr-code-info">
							<p class="subscriber-result">
								<img src='../assets/checkmark.svg' alt="Checkmark" width="60px" />
								<b>{{ result.subscriber }}</b>
							</p>
							<p v-html="result.message"></p>
						</div>

						<div class="qr-code-box">
							<qrcode-stream :camera="camera" :track="paintOutline" @decode="onDecode" @init="onInit">
								<div v-show="showScanConfirmationValid" class="scan-confirmation"></div>
								<div v-show="showScanConfirmationInvalid" class="scan-confirmation">
									<img src='../assets/error-icon.svg' alt="Error" width="128px" height="128px" />
								</div>
							</qrcode-stream>
						</div>
					</div>
				</div>
				
			</section>
		</ion-content>

		<ion-footer no-border>
			<div class="toggle-direction-container">
				<toggle-switch :options="toggleOptions" v-model="toggleSelected" @change="updateDirection($event.value)" /> 
			</div>
		</ion-footer>
	</div>
</template>

<script>
import axios from 'axios';

// Import component
import Loading from 'vue-loading-overlay';
import vSelect from "vue-select";
import ToggleSwitch from 'vuejs-toggle-switch';

// Import modal subscriber selection
import ModalSubscribersList from './ModalSubscribersList.vue';
// Import modal users list
import ModalUsersList from './ModalUsersList.vue';
// Import modal error
import ModalError from './ModalError.vue'

// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-select/dist/vue-select.css";

// ref. https://gruhn.github.io/vue-qrcode-reader/
import { QrcodeStream } from 'vue-qrcode-reader';

// Import certificate reader
import DCC from '../dcc';

export default {
	name: 'GreenPassPage',
	props: {
		msg: String
	},
	data: function(){
		return {
			isLoading: false,
			fullPage: true,
			firstInit: true,
			showQrCodeReader: false,
			timer: null,
			camera: 'off',
			result: null,
			showScanConfirmationValid: false,
			showScanConfirmationInvalid: false,
			validCode: undefined,
			error: '',
			conference: '',
			direction: 'IN',
			toggleSelected: '',
			subscription: '',
			conferences: [],
			toggleOptions: {
				layout: {
					color: '#007aff',
					backgroundColor: '#ffffff',
					borderColor: '#007aff',
					fontFamily: 'Arial',
					fontWeight: 'normal',
					fontWeightSelected: 'bold',
					squareCorners: false,
					noBorder: false
				},
				size: {
					fontSize: '1.2',
					height: '2.5',
					padding: '0.5',
					width: '15'
				},
				items: {
					delay: .4,
					preSelected: '',
					labels: [
						{name: 'ENTRATA', color: '#ffffff', backgroundColor: '#007aff'}, 
						{name: 'USCITA', color: '#ffffff', backgroundColor: '#007aff'}
					]
				}
			}
		}
	},

	components: {
		Loading,
		QrcodeStream,
		'v-select': vSelect,
		'toggle-switch': ToggleSwitch
	},

	mounted: function(){
		var token = localStorage.getItem("user_token");

		if(token === null || token == ''){
			location.href = '#/login';
		}

		this.direction = localStorage.getItem("subscribers_registration_direction");
		
		if (this.direction === 'IN') {
			this.toggleSelected = 'ENTRATA';
		} else if (this.direction === 'OUT') {
			this.toggleSelected = 'USCITA';
		} else {
			this.toggleSelected = 'ENTRATA';
			this.updateDirection('ENTRATA');
		}

		this.initConferences();
	},

	methods: {

		startQrCodeReader() {
			this.isLoading = true;
			this.result = null;
			this.error = '';
			this.showQrCodeReader = true;
			this.unpause();
		},

		async onDecode (content) {
			this.error = "";
			this.result = null;
			try {
				var decoded = await DCC.fromRaw(content);
				this.validCode = true;
				this.searchSubscriber(decoded);
			} catch {
				this.error = "QR Code non valido.";
				this.validCode = false;

				this.pause();
				await this.timeout(500);
				this.unpause();
			}
		},

		unpause () {
			this.camera = 'auto';
			this.validCode = undefined;
			this.timer = setTimeout(() =>{ 
				this.showQrCodeReader = false;
			}, 30000);
		},

		pause () {
			this.camera = 'off';
			clearTimeout(this.timer);
		},

		timeout (ms) {
			return new Promise(resolve => {
				window.setTimeout(resolve, ms)
			})
		},

		async onInit (promise) {
			if (this.firstInit) {
				this.isLoading = true;
				this.firstInit = false;
			}
			try {
				await promise
			} catch (error) {
				if (error.name === 'NotAllowedError') {
					this.error = "ERROR: you need to grant camera access permisson"
				} else if (error.name === 'NotFoundError') {
					this.error = "ERROR: no camera on this device"
				} else if (error.name === 'NotSupportedError') {
					this.error = "ERROR: secure context required (HTTPS, localhost)"
				} else if (error.name === 'NotReadableError') {
					this.error = "ERROR: is the camera already in use?"
				} else if (error.name === 'OverconstrainedError') {
					this.error = "ERROR: installed cameras are not suitable"
				} else if (error.name === 'StreamApiNotSupportedError') {
					this.error = "ERROR: Stream API is not supported in this browser"
				}
			} finally {
				this.showScanConfirmationValid = this.camera === "off" && this.validCode === true;
				this.showScanConfirmationInvalid = this.camera === "off" && this.validCode === false;
				this.isLoading = false;
			}
		},	

		reset () {
			this.firstInit = true;
			this.result = null;
			this.error = '';
		},

		paintOutline (detectedCodes, ctx) {
			for (const detectedCode of detectedCodes) {
				const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints

				ctx.strokeStyle = "red";

				ctx.beginPath();
				ctx.moveTo(firstPoint.x, firstPoint.y);
				for (const { x, y } of otherPoints) {
				ctx.lineTo(x, y);
				}
				ctx.lineTo(firstPoint.x, firstPoint.y);
				ctx.closePath();
				ctx.stroke();
			}
		},

		initConferences () {
			this.isLoading = true;

			var token = localStorage.getItem("user_token");

			axios.get(process.env.VUE_APP_BASE_URL + 'webApp/searchConferences?search=', {
				headers: {
					'Authorization': 'Bearer ' + token
				}
			})
			.then(response => {
				if (response.data.body.response == 'OK') {
					this.conferences = response.data.body.data; 

					if (this.conferences.length > 0) {
						var selectedConference = localStorage.getItem("selected_conference");

						if(selectedConference){
							this.conferences.forEach((conference) => {
								if (conference.id == selectedConference) {
									this.conference = conference;
								}
							});
						}
					}

					if (!this.conference) {
						localStorage.setItem("selected_conference", '');
						alert('Attenzione! Si prega di selezionare una conferenza.');
					}
				} else if (response.data.body.response == 'TOKEN-KO') {
					localStorage.setItem("user_token", "");
					location.href = '#/login';
				}
				
				this.isLoading = false;
			})
			.catch(e => {
				// eslint-disable-next-line
				console.log(e);
				
				alert('Errore nella ricerca delle conferenze.');

				this.isLoading = false;
			});
        },

		searchConferences (search, loading) {
			loading(true);

			var token = localStorage.getItem("user_token");

			axios.get(process.env.VUE_APP_BASE_URL + 'webApp/searchConferences?search='+encodeURI(search), {
				headers: {
					'Authorization': 'Bearer ' + token
				}
			})
			.then(response => {
				if (response.data.body.response == 'OK') {
					this.conferences = response.data.body.data; 
				} else if (response.data.body.response == 'TOKEN-KO') {
					localStorage.setItem("user_token", "");
					location.href = '#/login';
				}
				
				loading(false);
			})
			.catch(e => {
				// eslint-disable-next-line
				console.log(e);
				
				alert('Errore nella ricerca delle conferenze.');

				loading(false);
			});
        },

		rememberConference () {
			if (this.conference) {
				localStorage.setItem("selected_conference", this.conference.id);
			} else {
				localStorage.setItem("selected_conference", '');
				this.reset();
			}
		},

		updateDirection (value) {
			if (value == 'ENTRATA') {
				this.direction = 'IN';
			} else if (value == 'USCITA') {
				this.direction = 'OUT';
			} else {
				this.direction = '';
			}
			localStorage.setItem("subscribers_registration_direction", this.direction);
		},

		async searchSubscriber (code_content) {
			this.pause();

			this.isLoading = true;
			if (this.conference) {
				var token = localStorage.getItem("user_token");

				let params = new URLSearchParams();
				params.append('conference_id', this.conference.id);
				params.append('qr_code', JSON.stringify(code_content));

				axios.post(process.env.VUE_APP_BASE_URL + 'webApp/searchSubscriber', params, {
					headers: {
						'Authorization': 'Bearer ' + token
					}
				})
				.then(async response => {
					if (response.data.body.response == 'SUBSCRIBER_FOUND') {
						this.registerSubscriber(response.data.body.data);
					} else if (response.data.body.response == 'SUBSCRIBERS_FOUND') {
						let modalSubscribers = await this.$ionic.modalController.create({
							component: ModalSubscribersList,
							componentProps: {
								propsData: {
									subscribers: response.data.body.data
								}
							}
						});
						await modalSubscribers.present();

						let subscriber = await modalSubscribers.onDidDismiss();

						if (subscriber.data) {
							this.updateQrCodeSubscriber(subscriber.data.id, code_content._raw);
							this.registerSubscriber(subscriber.data);
						} else {
							this.unpause();
						}
					} else if (response.data.body.response == 'USERS_FOUND') {
						let modalUsers = await this.$ionic.modalController.create({
							component: ModalUsersList,
							componentProps: {
								propsData: {
									users: response.data.body.data
								}
							}
						});
						await modalUsers.present();
						await modalUsers.onDidDismiss();
						
						this.unpause();
					} else if (response.data.body.response == 'NOT_FOUND') {
						var error = "Nessun utente trovato per i dati:<br>";
						error += "Nome: " + code_content._payload.nam.gn + "<br>";
						error += "Cognome: " + code_content._payload.nam.fn + "<br>";
						error += "Data di nascita: " + code_content._payload.dob.split('-').reverse().join('/');

						let modalError = await this.$ionic.modalController.create({
							component: ModalError,
							componentProps: {
								propsData: {
									error: error
								}
							}
						});
						await modalError.present();
						await modalError.onDidDismiss();

						this.unpause();
					} else if (response.data.body.response == 'KO') {
						this.error = response.data.body.msg;

						await this.timeout(500);
						this.unpause();
					} else if (response.data.body.response == 'TOKEN-KO') {
						localStorage.setItem("user_token", "");
						location.href = '#/login';
					}
					
					this.isLoading = false;
				})
				.catch(async e => {
					// eslint-disable-next-line
					console.log(e);
					
					this.isLoading = false;
					this.error = "Errore nella ricerca dell'iscritto.";

					await this.timeout(500);
					this.unpause();
				});
			} else {
				this.isLoading = false;
				this.error = "Selezionare una conferenza.";

				await this.timeout(500);
				this.unpause();
			}
		},

		updateQrCodeSubscriber (subscription_id, qr_code) {
			this.isLoading = true;
			if (subscription_id && qr_code) {
				var token = localStorage.getItem("user_token");

				let params = new URLSearchParams();
				params.append('subscription_id', subscription_id);
				params.append('qr_code', qr_code);

				axios.post(process.env.VUE_APP_BASE_URL + 'webApp/updateQrCodeSubscriber', params, {
					headers: {
						'Authorization': 'Bearer ' + token
					}
				})
				.then(response => {
					if (response.data.body.response == 'KO') {
						alert(response.data.body.msg);
					} else if (response.data.body.response == 'TOKEN-KO') {
						localStorage.setItem("user_token", "");
						location.href = '#/login';
					}

					this.isLoading = false;
				})
				.catch(e => {
					// eslint-disable-next-line
					console.log(e);
					
					this.isLoading = false;
				});
			}
		},

		registerSubscriber (subscriber) { 
			this.isLoading = true;
			if (subscriber.id && this.direction) {
				var register = true;
				if (
					!subscriber.last_registration && this.direction == 'OUT'
					|| subscriber.last_registration.direction == this.direction
				) {
					var confirmMsg = "";
					if (!subscriber.last_registration) {
						confirmMsg = "Attenzione! Questa risulta essere la prima timbratura per l'iscritto. Si è sicuri di voler timbrare in USCITA?";
					} else if (subscriber.last_registration.direction == 'IN') {
						confirmMsg = "Attenzione! La precedente timbratura salvata per l'iscritto risulta in ENTRATA. Si è sicuri di voler timbrare nuovamente in ENTRATA?";
					} else if (subscriber.last_registration.direction == 'OUT') {
						confirmMsg = "Attenzione! La precedente timbratura salvata per l'iscritto risulta in USCITA. Si è sicuri di voler timbrare nuovamente in USCITA?";
					}
					
					if (!confirm(confirmMsg)) {
						register = false;
					}
				}

				if (register) {
					var token = localStorage.getItem("user_token");

					let params = new URLSearchParams();
					params.append('subscription_id', subscriber.id);
					params.append('direction', this.direction);

					axios.post(process.env.VUE_APP_BASE_URL + 'webApp/registerSubscriber', params, {
						headers: {
							'Authorization': 'Bearer ' + token
						}
					})
					.then(response => {
						if (response.data.body.response == 'OK') {
							var msg = '';
							if (response.data.body.data == 'LOW') {
								msg = "Il tempo di presenza dell'iscritto è <b>inferiore</b> al minimo richiesto.";
							} else if (response.data.body.data == 'OK') {
								msg = "Il tempo di presenza dell'iscritto ha <b>raggiunto</b> il minimo richiesto.";
							}

							this.result = {
								subscriber: subscriber.name.toUpperCase() + ' ' + subscriber.surname.toUpperCase(),
								message: msg
							};
						} else if (response.data.body.response == 'KO') {
							this.error = response.data.body.msg;
						} else if (response.data.body.response == 'TOKEN-KO') {
							localStorage.setItem("user_token", "");
							location.href = '#/login';
						}
						
						this.isLoading = false;

						this.unpause();
					})
					.catch(e => {
						// eslint-disable-next-line
						console.log(e);

						this.isLoading = false;
						this.error =  "Errore nella timbratura dell'iscritto.";

						this.unpause();
					});
				} else {
					this.isLoading = false;

					this.unpause();
				}
			} else {
				this.isLoading = false;
				this.error = "Errore nella timbratura dell'iscritto: dati mancanti.";

				this.unpause();
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	section {
		margin-top: 2em;
		margin-left: 1em;
		margin-right: 1em;
    }
	
	ion-card-content {
		color: #000000;
	}
	.error {
		font-weight: bold;
		color: red;
	}
	ion-footer{
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px;
	}
	.scan-confirmation {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, .8);
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
	}
	.toggle-direction-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 15px;
	}
	.vs__selected ~ .vs__search {
		position: absolute;
		top: 0px;
		width: 100%;
	}
	.vs__selected ~ .vs__search:focus {
		position: relative;
	}
	.subscriber-result {
		display: flex;
		align-items: center;
		font-size: 20px
	}
	.subscriber-result img {
		margin-right: 5px
	}
	.btn-start-qrcodereader {
		height: 72px;
		font-size: 24px;
		margin-top: 30px;
	}
</style>

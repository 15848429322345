import Vue from 'vue';
import Router from 'vue-router';

import LoginPage from '../login/LoginPage.vue';
import HomePage from '../home/HomePage.vue';
import ListPage from '../list/ListPage.vue';
import GreenPassPage from '../greenpass/GreenPassPage.vue';

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: LoginPage,
        },
        {
            path: '/',
            name: 'Home',
            component: HomePage,
        },
        {
            path: '/list',
            name: 'List',
            component: ListPage,
        },
        {
            path: '/greenpass',
            name: 'GreenPass',
            component: GreenPassPage,
        }
    ],
});